
export const TRANSLATIONS_EN = {
    page_title: "WhatsApp button generator by 2Chat",
    
    chatbox_title: "CHAT BOX TITLE",
    chatbox_title_desc: "Suggestion: use your brand name or company name here",
    chatbox_title_default: "Welcome!",

    status_text: "STATUS TEXT",
    status_text_desc: "Suggestion: add an informative text here, like your business hours or how long you usually take to reply",
    status_text_default: "We typically reply within 1 hour",

    greeting_message: "GREETING MESSAGE",
    greeting_message_desc: "This text will be displayed when the visitor on your site opens the chat widget",
    greeting_message_default: "Hey there! 👋\nHow can we help you?",

    placeholder_text: "VISITOR MESSAGE PLACEHOLDER",
    placeholder_text_desc: "This text will tell the visitors where they should write their message",
    placeholder_text_default: "Type your message...",

    phone_number_title: "Phone number",
    phone_number_title_desc: "This is your WhatsApp phone number where messages should arrive. It should always be in international format",

    color_scheme_title: "COLOR SCHEME",
    color_scheme_title_desc: "`Automatic` will select the scheme based on the visitor's browser configuration",
    color_scheme_automatic: "Automatic",
    color_scheme_light: "Light",
    color_scheme_dark: "Dark",

    position_title: "POSITION",
    position_title_desc: "Where in the screen the widget will be displayed",
    position_left: "Left",
    position_right: "Right",

    notification_badge_title: "SHOW NOTIFICATION BADGE",
    notification_badge_title_desc: "The badge will be displayed to influence the visitor to write you a message. Once the notification is displayed, it will remain hidden for 24 hours",
    notification_badge_show: "Show once every 24 hours",
    notification_badge_hide: "No notifications",

    generated_code_title: "Generated code",
    generated_code_title_desc: "Copy & Paste this code into your website's HTML to display the widget",
    generated_code_copy: "Copy code",
    generated_code_copied: "Code copied!",

    generator_title: "WhatsApp button generator",
    generator_subtitle: "Use this free tool to generate a custom WhatsApp button you can easily place into your website.",
    generator_preview_hint: "Check the preview by scrolling to the bottom"

};