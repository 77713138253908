import {
    Typography,
    Container,
    Grid,
    Card,
    CardHeader,
    Link,
    Stack,
    TextField,
    ToggleButtonGroup,
    ToggleButton,
    Box,  
    useMediaQuery
} from '@mui/material'


import { WhatsAppButtonEditor } from '2chat-whatsapp-widget';


// import BreadcrumbsMenu from '../components/BreadcrumbsMenu';

import Page from '../components/Page';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Code from '../components/Code';
import Iconify from '../components/Iconify';
import TelInput from '../components/TelInput';

function generateCode(accountName, statusText, messageText, placeholderText, phoneNumber, colorScheme, position, showNotification) {

  phoneNumber = phoneNumber?.replaceAll(' ', '');

  return `
  <script>
    window._2chatConfig = {
      phoneNumber:'${phoneNumber}',
      accountName: \`${accountName}\`,
      statusMessage: \`${statusText}\`,
      chatMessage: \`${messageText}\`,
      placeholder: '${placeholderText}',
      position: '${position}',
      colorScheme: '${colorScheme}',
      showNotification: ${showNotification === 'show' ? 'true' : 'false'},
    };
  
    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = 'https://widgets.2chat.io/index.js';
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', '_2chat'));
  </script>
  `;
}

function WidgetGridItem({accountName, statusText, messageText, placeholderText, position, colorScheme, showNotification}) {
  return (
    <Grid item lg={4.5} md={4.5} sm={12} xm={12} sx={{minHeight: '400px'}}>

      <WhatsAppButtonEditor 
            accountName={accountName} 
            statusMessage={statusText}
            chatMessage={messageText}
            placeholder={placeholderText}
            position={position}
            colorScheme={colorScheme === 'dark' ? 'dark' : 'light'}
            showNotification={showNotification === 'show'}

            // sx={{width: 1, height: 1}}

            buttonStyles={{position: 'absolute', bottom: '445%'}} 
            chatboxStyles={{position: 'absolute', bottom: '50%'}}
            
            // buttonStyles={{position: 'absolute', bottom: '10%', right: '20%'}} 
            // chatboxStyles={{position: 'absolute', left: '15%'}}
      />

    </Grid>

  );
}

export default function WhatsAppConfigurationPage() {
    const { t }                                   = useTranslation();
    const largerScreens                           = useMediaQuery('(min-width:1136px)');

    const [ accountName, setAccountName ]         = useState(t('chatbox_title_default'));
    const [ statusText, setStatusText ]           = useState(t('status_text_default'));
    const [ messageText, setMessageText ]         = useState(t('greeting_message_default'));
    const [ placeholderText, setPlaceholderText ] = useState(t('placeholder_text_default'));
    const [ phoneNumber, setPhoneNumber ]         = useState('');
    const [ colorScheme, setColorScheme ]         = useState(() => ['automatic']);
    const [ position, setPosition ]               = useState('right');
    const [ showNotification, 
            setShowNotification ]                 = useState('show');

    const handleColorSchemeSelection = (event, scheme) => {
      setColorScheme(scheme);
    };

    const handlePositionSelection = (event, position) => {
      setPosition(position);
    };

    const handleAccountNameChange = (e) => {
      setAccountName(e.target.value);
    }

    const handleStatusTextChange = (e) => {
      setStatusText(e.target.value);
    }

    const handleMessageTextChange = (e) => {
      setMessageText(e.target.value);
    }

    const handlePlaceholderTextChange = (e) => {
      setPlaceholderText(e.target.value);
    }

    const handleNotificationChange = (e) => {
      setShowNotification(e.target.value);
    }

    const handlePhoneNumberChange = (phoneNumber) => {
      setPhoneNumber(phoneNumber);
    }

    return (
        <Page title={`${t('generator_title')} - 2Chat`}>

            <Container maxWidth="lg" container spacing={1} >
              <Card sx={{p: largerScreens ? 3 : 0.3 }}>

                <Grid container sx={{pt: 2}} >
                  
                  <Grid item md={7.5} sm={12} xm={12} sx={{p: 2}}>

                    <Stack alignItems={"center"} direction="row" spacing={0.5} sx={{pb: 0.5}}> 
                      <Iconify icon='logos:whatsapp-icon' width={24} height={24}/>
                      <Typography variant={largerScreens ? "h4" : "h5"}>
                        {t('generator_title')}
                      </Typography>
                    </Stack>
                  
                    <Typography sx={{pb: largerScreens ? 3: 1 }} variant={largerScreens ? "body1" : "body2"}>
                      {t('generator_subtitle')}
                    </Typography>

                    { 
                      !largerScreens && 
                        <Typography variant="caption">
                          <i>{t('generator_preview_hint')}</i>
                        </Typography>
                    }

                    
                    <Stack sx={{p: 1, pt : !largerScreens ? 3 : 0 }}>
                      <Typography variant="overline" sx={{ mb: 0.5, display: 'block', color: 'text.secondary' }}>
                        {t('chatbox_title')}
                      </Typography>
                      <Typography variant="caption" sx={{ mb: 1, display: 'block', color: 'text.secondary' }}>
                        {t('chatbox_title_desc')}
                      </Typography>

                      <TextField onChange={handleAccountNameChange} placeholder="Content" value={accountName}></TextField>

                    </Stack>

                    <Stack sx={{p: 1}}>
                      <Typography variant="overline" sx={{ mb: 0.5, display: 'block', color: 'text.secondary' }}>
                        {t('status_text')}
                      </Typography>
                      <Typography variant="caption" sx={{ mb: 1, display: 'block', color: 'text.secondary' }}>
                        {t('status_text_desc')}
                      </Typography>

                      <TextField onChange={handleStatusTextChange} placeholder="Content" value={statusText}></TextField>
                    </Stack>

                    <Stack sx={{p: 1}}>
                      <Typography variant="overline" sx={{ mb: 0.5, display: 'block', color: 'text.secondary' }}>
                        {t('greeting_message')}
                      </Typography>
                      <Typography variant="caption" sx={{ mb: 1, display: 'block', color: 'text.secondary' }}>
                        {t('greeting_message_desc')}
                      </Typography>
                      
                      <TextField multiline={true} lines={2} onChange={handleMessageTextChange} placeholder="Content" value={messageText}></TextField>
                    </Stack>

                    <Stack sx={{p: 1}}>
                      <Typography variant="overline" sx={{ mb: 0.5, display: 'block', color: 'text.secondary' }}>
                        {t('placeholder_text')}
                      </Typography>
                      <Typography variant="caption" sx={{ mb: 1, display: 'block', color: 'text.secondary' }}>
                        {t('placeholder_text_desc')}
                      </Typography>
                      
                      <TextField multiline={true} lines={2} onChange={handlePlaceholderTextChange} placeholder="Content" value={placeholderText}></TextField>
                    </Stack>


                    <Stack sx={{p: 1}}>
                      <Typography variant="overline" sx={{ mb: 0.5, display: 'block', color: 'text.secondary' }}>
                        {t('phone_number_title')}
                      </Typography>
                      <Typography variant="caption" sx={{ mb: 1, display: 'block', color: 'text.secondary' }}>
                        {t('phone_number_title_desc')}
                      </Typography>
                      
                      <TelInput onPhoneNumberChanged={handlePhoneNumberChange}></TelInput>

                    </Stack>

                    <Stack sx={{p: 1}}>
                      <Typography variant="overline" sx={{ mb: 0.5, display: 'block', color: 'text.secondary' }}>
                        {t('color_scheme_title')}
                      </Typography>
                      <Typography variant="caption" sx={{ mb: 1, display: 'block', color: 'text.secondary' }}>
                        {t('color_scheme_title_desc')}
                      </Typography>
                      
                      <ToggleButtonGroup
                        value={colorScheme}
                        onChange={handleColorSchemeSelection}
                        exclusive
                        aria-label="text formatting"
                      >
                        <ToggleButton value="automatic" aria-label="automatic">
                          {t('color_scheme_automatic')}
                        </ToggleButton>
                        <ToggleButton value="light" aria-label="light">
                          {t('color_scheme_light')}
                        </ToggleButton>
                        <ToggleButton value="dark" aria-label="dark">
                          {t('color_scheme_dark')}
                        </ToggleButton>
                      </ToggleButtonGroup>
                      
                    </Stack>

                    <Stack sx={{p: 1}}>
                      <Typography variant="overline" sx={{ mb: 0.5, display: 'block', color: 'text.secondary' }}>
                        {t('position_title')}
                      </Typography>
                      <Typography variant="caption" sx={{ mb: 1, display: 'block', color: 'text.secondary' }}>
                        {t('position_title_desc')}
                      </Typography>
                      
                      <ToggleButtonGroup
                        value={position}
                        onChange={handlePositionSelection}
                        exclusive>

                        <ToggleButton value="left" aria-label="left">
                          {t('position_left')}
                        </ToggleButton>
                        <ToggleButton value="right" aria-label="right">
                          {t('position_right')}
                        </ToggleButton>
                      </ToggleButtonGroup>
                      
                    </Stack>

                    <Stack sx={{p: 1}}>
                      <Typography variant="overline" sx={{ mb: 0.5, display: 'block', color: 'text.secondary' }}>
                        {t('notification_badge_title')}
                      </Typography>
                      <Typography variant="caption" sx={{ mb: 1, display: 'block', color: 'text.secondary' }}>
                        {t('notification_badge_title_desc')}
                      </Typography>
                      
                      <ToggleButtonGroup
                        value={showNotification}
                        onChange={handleNotificationChange}
                        exclusive>

                        <ToggleButton value={'show'} aria-label="left">
                          {t('notification_badge_show')}
                        </ToggleButton>
                        <ToggleButton value={'hide'} aria-label="right">
                          {t('notification_badge_hide')}
                        </ToggleButton>

                      </ToggleButtonGroup>
                    </Stack>  

                    <Stack sx={{p: 1}}>
                      <Typography variant="overline" sx={{ mb: 0.5, display: 'block', color: 'text.secondary' }}>
                        {t('generated_code_title')}
                      </Typography>
                      <Typography variant="caption" sx={{ mb: 1, display: 'block', color: 'text.secondary' }}>
                        {t('generated_code_title_desc')}
                      </Typography>

                      <Code>
                        {generateCode(accountName, statusText, messageText, placeholderText, phoneNumber, colorScheme, position, showNotification)}
                      </Code>

                    </Stack>
                  </Grid>

                  
                  <Grid item lg={4.5} md={4.5} sm={12} sx={{p: 2, minHeight: '400px', width: '100%'}}>
                    {/* <Box sx={{backgroundColor: 'red', minHeight: '400px'}}> */}
                    
                    <WhatsAppButtonEditor 
                      
                      accountName={accountName} 
                      statusMessage={statusText}
                      chatMessage={messageText}
                      placeholder={placeholderText}
                      position={position}
                      colorScheme={colorScheme === 'dark' ? 'dark' : 'light'}
                      showNotification={showNotification === 'show'}
          
                      sx={{width: 1, height: 1}}
          
                      // buttonStyles={{position: 'absolute', bottom: '45%'}} 
                      // chatboxStyles={{position: 'absolute', bottom: '50%'}}
                      
                      // buttonStyles={{position: 'absolute', bottom: '10%', right: '20%'}} 
                      // chatboxStyles={{position: 'absolute', botom: '10%', left: '15%'}}

                      // buttonStyles={{position: 'absolute', bottom: '1%', right: '2%'}} 
                      // chatboxStyles={{position: 'absolute', botom: '1%', left: '1.5%'}}

                      buttonStyles={ largerScreens ? { position: 'absolute', bottom: '45%' } : { position: 'absolute' } } 
                      chatboxStyles={ largerScreens ? { position: 'absolute', bottom: '50%' } : { position: 'absolute' } }
                    /> 
                
                    
                  </Grid>

                </Grid>
                <br />
                <Stack alignItems={"center"}>
                  <Typography variant="caption">
                    ⚡ Powered by <a href='https://2chat.co?ref=whatsapp-widget-generator' target='_blank'>2Chat</a> — <a href='mailto:support@2chat.co'>support@2chat.co</a> 
                  </Typography>

                  <Typography variant="caption">
                    © {new Date().getFullYear()} Nagence, Inc.
                  </Typography>
                </Stack>

              </Card>

              
            </Container> 

        </Page>
    );
}

