import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
 
import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_ES } from "./es/translations";
 
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    
    .init({
        // lng: 'en',
        resources: {
            en: {
                    translation: TRANSLATIONS_EN
                },
            es: {
                    translation: TRANSLATIONS_ES
                }
        },
        fallbackLng: 'en',
        debug: true
    });

// i18n.changeLanguage("es");

export default i18n;