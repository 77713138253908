import * as React from 'react';

import ThemeProvider from './theme';
import ThemeSettings from './components/settings';

import WhatsAppConfigurationPage from './pages/WhatsAppConfiguration';

export default function App() {

  return (

    <ThemeProvider>
      <ThemeSettings>
        <WhatsAppConfigurationPage />
      </ThemeSettings>
    </ThemeProvider>
  );
}