
import { IconButton, Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import Iconify from './Iconify';


export default function Code(props) {
    const { t }                 = useTranslation();
    const [ tooltipText, 
            setTooltipText ]    = useState(t('generated_code_copy'));

    const onCopyToClipboardClicked = (e) => {
        setTooltipText(t('generated_code_copied'));
        setTimeout(e => setTooltipText(t('generated_code_copy')), 4000);

        navigator.clipboard.writeText(props.children);
    };

    return (
        <Box sx={{position: 'relative'}}>
            <Tooltip title={tooltipText} arrow>
                <IconButton sx={{position: 'absolute', right: '5px', top: '5px'}} onClick={onCopyToClipboardClicked}>
                    <Iconify icon='akar-icons:copy' />
                </IconButton>
            </Tooltip>

            <SyntaxHighlighter 
                language="javascript" 
                style={docco} 
                lineProps={{style: {wordBreak: 'break-all', whiteSpace: 'pre-wrap'}}}   // makes the highlighther responsive
                wrapLines={true} >

                {props.children}
            </SyntaxHighlighter>
        </Box>
    );
}