
export const TRANSLATIONS_ES = {
    page_title: "Generador de botón de WhatsApp - 2Chat",

    chatbox_title: "Título principal",
    chatbox_title_desc: "Sugerencia: puedes usar el nombre de tu marca o empresa aquí",
    chatbox_title_default: "¡Te damos la bienvenida!",

    status_text: "Texto de estado",
    status_text_desc: "Sugerencia: agrega un text informativo aquí, como por ejemplo tus horarios de atención o en cuánto tiempo sueles responder a los mensajes.",
    status_text_default: "Solemos responder en menos de una hora",

    greeting_message: "Mensaje de saludo",
    greeting_message_desc: "Este texto será mostrado a los visitantes de tu página como un mensaje nuevo",
    greeting_message_default: "Hola! 👋\nCómo podemos ayudarte?",

    placeholder_text: "Caja de respuesta",
    placeholder_text_desc: "Este texto sirve como ayuda para que tu visitante sepa dónde escribir su respuesta",
    placeholder_text_default: "Escribe tu mensaje aquí...",

    phone_number_title: "Número de teléfono",
    phone_number_title_desc: "Este valor debe ser el número de WhatsApp a donde los mensajes deberían llegarte. Siempre debe estar en formato internacional",

    color_scheme_title: "Esquema de colores",
    color_scheme_title_desc: "Elige `Automático` para que el mejor color sea detectado en base al navegador de tu visitante",
    color_scheme_automatic: "Automático",
    color_scheme_light: "Claro",
    color_scheme_dark: "Oscuro",

    position_title: "Posición",
    position_title_desc: "En qué parte de la pantalla (abajo) la burbuja de chat será mostrada",
    position_left: "Izquierda",
    position_right: "Derecha",

    notification_badge_title: "Mostrar notificaciones",
    notification_badge_title_desc: "Esta insigina será mostrada para influenciar a tus visitantes a escribirte un mensaje. Cuando la notificación es mostrada, quedará oculta por 24 horas para evitar ser una molestia",
    notification_badge_show: "Mostrar una vez cada 24 horas",
    notification_badge_hide: "No mostrar nada",

    generated_code_title: "Código generado",
    generated_code_title_desc: "Copia y paga este código dentro del contenido HTML de tu sitio web",
    generated_code_copy: "Copiar Código",
    generated_code_copied: "Código ha sido copiado!",

    generator_title: "Generador de botón de WhatsApp",
    generator_subtitle: "Utiliza esta herramienta gratuita para crear un botón flotante personalizado de WhatsApp que puedes colocar en tu propio sitio web.",
    generator_preview_hint: "Mira el botón en vista previa yendo hasta abajo"
};