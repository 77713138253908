
import { MuiTelInput, isValidPhoneNumber } from 'mui-tel-input'
import { useState } from 'react';

const PREFERRED_COUNTRIES = [ 'IN', 'BR', 'US', 'CA', 'ID', 
                                'RU', 'MX', 'DE', 'IT', 
                                'ES', 'GB', 'AR', 'CO', 
                                'PE', 'VE', 'PY', 'BO', 'EC', 'GT',
                                'CL', 'UY', 'PA', 'CR',
                                'FR', 'NL', 'PT', 'PL',
                                'IE', 'PT', 'NI', 'NG', 'ZA', 'TR' ];

export default function TelInput({ onPhoneNumberChanged }) {
    const [ value, setValue ]   = useState('+1');
    const [ error, setError ]   = useState(false); 

    const handleChange = (newValue) => {
        setError(!isValidPhoneNumber(newValue))
        setValue(newValue);

        if ( onPhoneNumberChanged )
            onPhoneNumberChanged(newValue);
    }
    
    return (
        <MuiTelInput error={error} value={value} onChange={handleChange} preferredCountries={PREFERRED_COUNTRIES} forceCallingCode={true}/>
    );
}